// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto');

// Variables
@import 'variables';

$font-family-sans-serif: 'Roboto', sans-serif;

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/fontawesome';
@import '~@fortawesome/fontawesome-free/css/regular';
@import '~@fortawesome/fontawesome-free/css/solid';
@import '~@fortawesome/fontawesome-free/css/brands';

body {
	background-color: #fff;
}

i {
	color: #72b0fc
}

.nav-item > a:hover {
	opacity: 0.5;
}

nav {
	.navbar {
		background-color: #ccc;
		// Animation
		-webkit-transition: all 0.4s ease;
		transition: all 0.4s ease;

		.shrink {
			height: 35px;
		}
	}
}

@media (max-width: 991px) {
	.navbar {
		line-height: 1.1 !important;

		.navbar-brand {
			flex-shrink: initial;
			flex-basis: auto;
			word-break: break-word;
			padding-right: 2rem;

			img {
				height: 4rem !important;
			}
		}
	}
}

.vertical-carousel {
	background-image: url("/img/background.jpg");
	background-size: cover;

	section {
		opacity: 0.7;
	}
}

.scrollspy-div {
	height: 100%;
	margin-top: .5rem;
	overflow: auto;
}

.text-justify {
	text-align: justify;
	text-justify: inter-word;
}
